import { useState, useEffect } from "react";
import * as sc from "../styled/scHeader";
import { Logo } from "../assets/dynamicAssets";
import RightArrow from "../assets/common/rightArrow.svg";

const Header = () => {
  const [sticky, setSticky] = useState(false);

  const stickyHeaderFn = () => {
    const scrollY = window.scrollY;
    if (scrollY >= 50 && !sticky) setSticky(true);
    else if (scrollY < 50 && sticky) setSticky(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", stickyHeaderFn);
    return () => document.removeEventListener("scroll", stickyHeaderFn);
  });

  return (
    <sc.HeaderWrapper className="header" sticky={sticky}>
      <sc.HeaderContainer>
        <sc.HeaderLogo href="#">
          <Logo />
        </sc.HeaderLogo>
        <sc.HeaderButtonGroup>
          <sc.HeaderButton
            className="header-button"
            href="https://acmhacettepe.com"
            target="_blank"
            $outline
            $disabledOnMobile
          >
            ACM Hacettepe
          </sc.HeaderButton>
          <sc.HeaderButton
            className="header-button"
            href="https://forms.gle/ocJAwAacCup15w2C6"
            target="_blank"
          >
            Başvur
            <img src={RightArrow} alt="Başvur" />
          </sc.HeaderButton>
        </sc.HeaderButtonGroup>
      </sc.HeaderContainer>
    </sc.HeaderWrapper>
  );
};

export default Header;
