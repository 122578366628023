import styled from "styled-components";
import { COLORS } from "../utils";

export const EntranceWrapper = styled.div`
  background-image: ${COLORS.gradient};
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
`;

export const EntranceContainer = styled.div`
  width: 12rem;
  height: 12rem;
  overflow: hidden;
`;

export const EntranceLogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
`;

export const EntranceLogo = styled.img`
  width: 8rem;
`;
