import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";

export const HeroWrapper = styled.div`
  background-color: ${COLORS.mainBackground} !important;
  background: url(${(props) => props.image}) no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1000;
  background-size: 100%;
  background-position: 0 160%;

  @media (max-width: 1800px) {
    background-position: 0 0;
  }

  @media (max-width: 1200px) {
    background-size: auto;
  }

  @media (max-width: 1024px) {
    background-position: 0 -20rem;
  }

  @media (max-width: 600px) {
    background-position: 0 -30rem;
  }
`;

export const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 15rem 0;

  @media (max-width: 1024px) {
    padding-bottom: 2.5rem;
  }

  svg {
    margin-top: 10rem;

    @media (max-width: 1024px) {
      width: 55rem;
      margin-top: 0;
    }

    @media (max-width: 600px) {
      width: 85%;
      height: 100%;
      padding: 5rem 0;
      margin: 0 auto;
    }
  }
`;

export const HeroHeading = styled.h1`
  color: white;
  font-size: 9rem;
  font-weight: ${FONT_WEIGHTS.bold};
  margin-bottom: 1.5rem;

  @media (max-width: 1024px) {
    font-size: 7rem;
  }

  @media (max-width: 600px) {
    font-size: 4.5rem;
    margin-bottom: 1rem;
  }
`;

export const HeroDescription = styled.p`
  font-size: 2.6rem;
  font-weighr: ${FONT_WEIGHTS.semibold};
  color: ${COLORS.paragraphColor};

  @media (max-width: 1024px) {
    font-size: 2.2rem;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;
