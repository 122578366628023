import gsap, { Expo, Power2 } from "gsap";

const EntranceAnimation = () => {
  gsap
    .timeline()
    .to(".entrance-logo", {
      delay: 0.5,
      duration: 1,
      yPercent: -100,
      ease: Power2.easeOut,
    })
    .to(".entrance-logo", {
      duration: 1,
      yPercent: -200,
      ease: Power2.easeIn,
    })
    .to(
      ".entrance-wrapper",
      {
        delay: 0.5,
        duration: 1.5,
        yPercent: -100,
        ease: Expo.easeInOut,
      },
      "-=1"
    );
};

export default EntranceAnimation;
