import styled from "styled-components";
import { COLORS } from "../utils";

export const SliderContainer = styled.div`
  padding-top: 6rem;
  position: relative;

  .swiper {
    width: 90%;
    margin: 0 auto;

    @media only screen and (max-width: 1200px) {
      width: 90%;
    }

    @media only screen and (max-width: 768px) {
      width: 85%;
    }
  }

  @media (max-width: 600px) {
    padding-top: 4rem;
  }
`;

export const SliderPrevButton = styled.button`
  position: absolute;
  top: 47.5%;
  left: -2.5rem;
  transform: translateY(-50%);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.5;
  }

  @media only screen and (max-width: 1200px) {
    left: 0;
    transform: translateY(-50%) scale(0.9);
  }

  @media only screen and (max-width: 768px) {
    left: -1rem;
    transform: translateY(-50%) scale(0.7);
  }
`;

export const SliderNextButton = styled.button`
  position: absolute;
  top: 47.5%;
  right: -2.5rem;
  transform: translateY(-50%);
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 0.5;
  }

  @media only screen and (max-width: 1200px) {
    right: 0;
    transform: translateY(-50%) scale(0.9);
  }

  @media only screen and (max-width: 768px) {
    right: -1rem;
    transform: translateY(-50%) scale(0.7);
  }
`;

export const SliderButtonIcon = styled.img`
  border-radius: 50%;
  border: 1px solid ${COLORS.border};
`;

export const SliderItem = styled.div`
  width: 100%;
  padding: 0 3rem;

  @media only screen and (max-width: 1200px) {
    padding: 0 5rem;
  }

  @media only screen and (max-width: 1024px) {
    padding: 0 3.5rem;
  }

  @media only screen and (max-width: 768px) {
    padding: 0 2.5rem;
  }
`;

export const SliderItemImage = styled.img`
  height: 45rem;
  width: 100%;
  object-fit: cover;
  border: 1px solid ${COLORS.border};

  @media only screen and (max-width: 400px) {
    height: 35rem;
  }
`;
