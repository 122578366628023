import styled from "styled-components";
import { COLORS } from "../utils";
import { Container } from "./scMixins";

export const DetailsWrapper = styled.div`
  padding: 12.5rem 0 0;
  background-color: ${COLORS.mainBackground};
  position: relative;
  z-index: 1;

  @media (max-width: 1200px) {
    padding-top: 10rem;
  }

  @media (max-width: 1024px) {
    padding-top: 2rem;
  }

  @media (max-width: 900px) {
    padding: 4rem 0 1rem;
  }
`;

export const DetailsContainer = styled.div`
  ${Container}

  svg {
    width: 100%;

    @media (max-width: 1200px) {
      transform: translateY(2.5rem);
    }

    @media (max-width: 1024px) {
      transform: translateY(5rem);
    }

    @media (max-width: 900px) {
      display: none;
    }
  }

  .slider {
    @media (min-width: 901px) {
      display: none;
    }

    @media (max-width: 900px) {
      display: block;
    }
  }
`;

export const AllPrizes = styled.img`
  padding-top: 0px;
  width: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 5rem;

  & > div {
    width: 46%;

    @media (max-width: 1024px) {
      width: 47.5%;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;
