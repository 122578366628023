import gsap, { Power2 } from "gsap";

const FooterAnimation = () => {
  gsap.set(".sponsors-animated", { y: 50, opacity: 0 });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".sponsors-wrapper",
        start: "30% bottom",
        end: "center center",
      },
    })
    .to(".sponsors-animated", {
      duration: 1,
      y: 0,
      opacity: 1,
      ease: Power2.easeOut,
      stagger: 0.1,
    });
};

export default FooterAnimation;
