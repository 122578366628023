import styled from "styled-components";
import { COLORS } from "../utils";
import { Button } from "./scMixins";

export const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100000;
  transition: padding 0.5s ease, background 0.5s ease;

  svg {
    transition: width 0.5s ease;
  }

  a {
    transition: all 0.5s ease;
  }

  ${(props) =>
    props.sticky &&
    `
    padding: 5px 0;
    background: rgba(18, 18, 28, 0.89);
    backdrop-filter: blur(9.7px);
    -webkit-backdrop-filter: blur(9.7px);
    border-bottom: 1px solid ${COLORS.border};

    @media (max-width: 600px) {
      padding: 1rem 0;
    }

    & > div {
      padding: 0 2rem;
    }

    svg {
      width: 4.5rem;

      @media (max-width: 600px) {
        width: 4rem !important;
        height: 100% !important;
      }
    }

    .header-button {
      padding: 1.4rem 2.2rem;
    }
  `}
`;

export const HeaderContainer = styled.div`
  width: 95%;
  max-width: 140rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-image: url(${(props) => props.image});
  transition: all 0.5s ease;
`;

export const HeaderLogo = styled.a`
  @media (max-width: 1024px) {
    svg {
      width: 5rem;
      height: 100%;
    }
  }

  path {
    transition: fill 0.5s ease;
  }

  &:hover {
    path {
      fill: ${COLORS.purple};
    }
  }
`;

export const HeaderButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 1.75rem;
`;

export const HeaderButton = styled.a`
  ${Button}

  @media (max-width: 600px) {
    padding: 1.4rem 2.25rem;
  }

  ${(props) =>
    props.$disabledOnMobile &&
    `
      @media (max-width: 600px) {
        display: none;
      }
    `}
`;
