import { useEffect } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Info from "./components/Info";
import Huprog from "./components/Huprog";
import Details from "./components/Details";
import PastHuprogs from "./components/PastHuprogs";
import Footer from "./components/Footer";
import GlobalStyles from "./styled/scGlobals";
import Entrance from "./components/Entrance";

// Animations
import HeroAnimation from "./animations/HeroAnimation";
import InfoAnimation from "./animations/InfoAnimation";
import HuprogAnimation from "./animations/HuprogAnimation";
import DetailsAnimation from "./animations/DetailsAnimation";
import FooterAnimation from "./animations/FooterAnimation";

// Utils
import { isTouchDevice } from "./utils";
import EntranceAnimation from "./animations/EntranceAnimation";

const App = () => {
  useEffect(() => {
    EntranceAnimation();

    // For better performance, animations (except for entrance) are disabled on mobile
    if (isTouchDevice()) {
      return;
    }

    HeroAnimation();
    InfoAnimation();
    HuprogAnimation();
    DetailsAnimation();
    FooterAnimation();
  }, []);

  return (
    <main>
      <GlobalStyles />
      <Entrance />
      <Header />
      <Hero />
      <Info />
      <Huprog />
      <Details />
      <PastHuprogs />
      <Footer />
    </main>
  );
};

export default App;
