import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";

export const PastHuprogsWrapper = styled.section`
  width: 100%;
  padding: 10rem 0 12.5rem;
  position: relative;
  overflow: hidden;
  background-color: ${COLORS.mainBackground};
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 5rem 0 7.5rem;
  }
`;

export const PastHuprogsContainer = styled.div`
  width: 95%;
  max-width: 115rem;
  text-align: center;
  margin: 0 auto;
  position: relative;
`;

export const PastHuprogsTitle = styled.h2`
  font-size: 3.2rem;
  font-weight: ${FONT_WEIGHTS.semibold};
  color: white;

  span {
    color: ${COLORS.primaryColor};
  }

  @media only screen and (max-width: 1024px) {
    font-size: 2.8rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2.4rem;
  }
`;
