import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";

export const AccordionContainer = styled.div`
  padding: 3rem;
  margin: 3.5rem 0;
  background: ${COLORS.secondaryBackground};
  border: 1px solid ${COLORS.border};
  cursor: pointer;
  overflow: hidden;
  height: 8rem;
  transition: height 0.5s ease;

  @media (max-width: 1024px) {
    margin: 1.5rem 0;
  }

  @media (max-width: 600px) {
    height: 7rem;
    padding: 2.5rem 1.75rem;
  }

  ${(props) =>
    props.open &&
    `
    height: 57.5rem;

    h3 {
      color: white;
    }

    img {
      transform: rotateZ(180deg);
    }

    @media (max-width: 1200px) {
      height: 60rem;
    }

    @media (max-width: 1024px) {
      height: auto;
    }
  `}

  ${(props) =>
    props.color &&
    props.color === "pink" &&
    `
    background-image: linear-gradient(90deg, #291D34, #151520 80%);
  `}

  ${(props) =>
    props.color &&
    props.color === "blue" &&
    `
    background-image: linear-gradient(90deg, #1C2032, #151520 80%);
  `}

  ${(props) =>
    props.color &&
    props.color === "purple" &&
    `
    background-image: linear-gradient(90deg, #1F1B32, #151520 80%);
  `}

  ${(props) =>
    props.color &&
    props.color === "orange" &&
    `
    background-image: linear-gradient(90deg, #281D2A, #151520 80%);
  `}
`;

export const AccordionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionTitle = styled.h3`
  font-size: 1.6rem;
  color: ${COLORS.paragraphColor};
  font-weight: ${FONT_WEIGHTS.medium};
  transition: color 0.5s ease;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

export const AccordionChevronDown = styled.img`
  transition: transform 0.5s ease;

  @media (max-width: 600px) {
    width: 1.25rem;
  }
`;

export const AccordionContent = styled.p`
  font-size: 1.4rem;
  color: ${COLORS.paragraphColor};
  line-height: 1.6;
  margin-top: 3rem;
`;
