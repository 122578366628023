import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";
import { Container } from "./scMixins";

export const HuprogWrapper = styled.div`
  background-color: ${COLORS.secondaryBackground};
  position: relative;
  z-index: 1;
`;

export const HuprogContainer = styled.div`
  ${Container}
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 60%;
  padding: 12.5rem 0;

  @media (max-width: 1200px) {
    padding: 7.5rem 0;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 5rem 0;
  }
`;

export const DescriptionTitle = styled.h2`
  font-size: 4.2rem;
  font-weight: ${FONT_WEIGHTS.semibold};

  @media (max-width: 1024px) {
    font-size: 3.6rem;
  }

  @media (max-width: 600px) {
    font-size: 3.2rem;
  }
`;

export const Description = styled.p`
  width: 90%;
  color: ${COLORS.paragraphColor};
  font-size: 1.5rem;
  line-height: 1.6;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const LogoContainer = styled.div`
  width: 38rem;
  background: linear-gradient(0, #241a2c, #171624, #1c1b31);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 3.5rem;
    height: 50rem;
  }
`;

export const Logo = styled.img`
  width: 20rem;

  @media (max-width: 900px) {
    width: 20%;
  }

  @media (max-width: 600px) {
    width: 10rem;
  }
`;

export const LogoOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background-color: ${COLORS.secondaryBackground};
`;
