import { useState } from "react";
import * as sc from "../styled/scAccordion";
import ChevronDownIcon from "../assets/accordion/chevronDown.svg";

const Accordion = ({ title, content, color, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <sc.AccordionContainer
      open={open}
      color={color}
      className={className}
      onClick={() => setOpen((state) => !state)}
    >
      <sc.AccordionHeaderContainer>
        <sc.AccordionTitle>{title}</sc.AccordionTitle>
        <sc.AccordionChevronDown src={ChevronDownIcon} />
      </sc.AccordionHeaderContainer>
      <sc.AccordionContent>{content}</sc.AccordionContent>
    </sc.AccordionContainer>
  );
};

export default Accordion;
