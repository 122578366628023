import * as sc from "../styled/scHuprog";
import Logo from "../assets/common/logo.svg";

const Huprog = () => (
  <sc.HuprogWrapper className="huprog-wrapper">
    <sc.HuprogContainer>
      <sc.TextContainer>
        <sc.DescriptionTitle className="huprog-animated">
          HU<span>PROG'24</span>
        </sc.DescriptionTitle>
        <sc.Description className="huprog-animated">
          <br />
          HUPROG`24 (Hacettepe Üniversitesi Programlama Yarışması); Türkiye'deki
          tüm öğrencilere açık olan ve finalinin 23 Mart tarihinde, Hacettepe
          Üniversitesi Beytepe Kampüsü'nde gerçekleştirilmiş olan bir
          programlama yarışmasıdır. İlkini 2016'da gerçekleştirdiğimiz ve
          başarıyla sonuçlandırdığımız etkinliğimizin bu sene; çapını ve
          süresini artırıp daha geniş kitlelere ulaşmayı hedefliyoruz.
          <br /> <br />
          Yarışma, algoleague ortamı üzerinde gerçekleştirilip; katılım
          takımlar halinde olmuştur. Bireysel katılımlar, tek kişilik takımlar
          gibi değerlendirilmiştir.Takımlar maksimum 3 kişilik olup yarışmaya
          katılacak takımlar, yaptığımız ön elemeyle belirlenip ve eleme
          sonucunda ilk 20 sırada bulunan takımlara finale katılma hakkı
          tanınmıştır.
        </sc.Description>
      </sc.TextContainer>
      <sc.LogoContainer>
        <sc.LogoOverlay className="huprog-logo-overlay" />
        <sc.Logo src={Logo} />
      </sc.LogoContainer>
    </sc.HuprogContainer>
  </sc.HuprogWrapper>
);

export default Huprog;
