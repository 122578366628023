import * as sc from "../styled/scPastHuprogs";
import Slider from "./Slider";
import { PASTHUPROGS } from "../utils";

const PastHuprogs = () => (
  <sc.PastHuprogsWrapper>
    <sc.PastHuprogsContainer>
      <sc.PastHuprogsTitle>
        Geçmiş <span>HUPROG'lar</span>
      </sc.PastHuprogsTitle>
      <Slider data={PASTHUPROGS} />
    </sc.PastHuprogsContainer>
  </sc.PastHuprogsWrapper>
);

export default PastHuprogs;
