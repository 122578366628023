import * as sc from "../styled/scEntrance";
import Logo from "../assets/common/logo.svg";

const Entrance = () => {
  return (
    <sc.EntranceWrapper className="entrance-wrapper">
      <sc.EntranceContainer>
        <sc.EntranceLogoContainer className="entrance-logo">
          <sc.EntranceLogo src={Logo} />
        </sc.EntranceLogoContainer>
      </sc.EntranceContainer>
    </sc.EntranceWrapper>
  );
};

export default Entrance;
