import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";
import { Button, Container } from "./scMixins";

export const InfoWrapper = styled.div`
  background: ${COLORS.mainBackground};
  z-index: 1;
  position: relative;
  margin-top: -5rem;
  padding: 0 0 12.5rem;

  @media (max-width: 900px) {
    padding-bottom: 7.5rem;
  }
`;

export const DetailsContainer = styled.div`
  margin: 5rem 0;
`;

export const Title = styled.h2`
  text-align: center;
  font-weight: ${FONT_WEIGHTS.semibold};
  font-size: 3.2rem;
  line-height: 1;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    font-size: 3rem;
  }

  @media (max-width: 600px) {
    font-size: 2.8rem;
    margin-bottom: 1.5rem;
  }
`;

export const Details = styled.p`
  text-align: center;
  width: 45%;
  color: ${COLORS.paragraphColor};
  font-size: 1.6rem;
  line-height: 1.6;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 85%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${Container};
  padding: 0 2.5rem;
  padding-top: 16rem;
  gap: 4rem;

  @media (max-width: 1200px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 1024px) {
    gap: 2rem;
    padding-top: 0;
    align-items: center;
  }

  @media (max-width: 900px) {
    gap: 4rem;
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 29rem;
  height: 42rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 3.5rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    height: 37.5rem;
  }

  @media (max-width: 900px) {
    height: 42rem;
  }

  @media (max-width: 400px) {
    width: 100%;
    height: 50rem;
  }

  @media (max-width: 350px) {
    height: 45rem;
  }

  &:nth-child(1) {
    background: linear-gradient(0, #2a1e27, #151520);
  }

  &:nth-child(2) {
    transform: translateY(-12.5rem);
    background: linear-gradient(0, #281b2f, #151520);

    @media (max-width: 1024px) {
      transform: none;
    }
  }

  &:nth-child(3) {
    background: linear-gradient(0, #282340, #151520);
  }
`;

export const CardOverlay = styled.div`
  background-color: ${COLORS.mainBackground};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translateY(100%);
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ApplyButton = styled.a`
  ${Button}
  transform: translateY(-4rem);
  padding: 2rem 4rem;
  font-size: 1.5rem;

  &:hover {
    transform: translateY(-4rem) scale(1.05);
  }

  @media (max-width: 1024px) {
    margin-top: 5rem;
    transform: none;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const CardImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export const CardHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: ${FONT_WEIGHTS.semibold};
  margin-bottom: 5px;

  @media (max-width: 400px) {
    font-size: 1.8rem;
  }
`;

export const CardDescription = styled.p`
  font-size: 1.5rem;
  color: ${COLORS.paragraphColor};

  @media (max-width: 400px) {
    font-size: 1.7rem;
  }
`;
