import React from "react";
import ReactDOM from "react-dom/client";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import App from "./App";

gsap.registerPlugin(ScrollTrigger);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
