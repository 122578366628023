import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import * as sc from "../styled/scSlider";
import PrevIcon from "../assets/slider/prevIcon.svg";
import NextIcon from "../assets/slider/nextIcon.svg";

const Slider = ({ data }) => {
  const [swiper, setSwiper] = useState();

  return (
    <sc.SliderContainer className="slider">
      <sc.SliderPrevButton
        onClick={() => swiper?.slideTo(swiper?.activeIndex - 1)}
      >
        <sc.SliderButtonIcon src={PrevIcon} alt="Önceki" />
      </sc.SliderPrevButton>
      <sc.SliderNextButton
        onClick={() => swiper?.slideTo(swiper?.activeIndex + 1)}
      >
        <sc.SliderButtonIcon src={NextIcon} alt="Sonraki" />
      </sc.SliderNextButton>
      <Swiper
        onSwiper={(swiper) => setSwiper(swiper)}
        grabCursor
        slidesPerView={1}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
        }}
      >
        {data.map((pasthuprog, i) => (
          <SwiperSlide key={i}>
            <sc.SliderItem>
              <sc.SliderItemImage src={pasthuprog.img} alt="" />
            </sc.SliderItem>
          </SwiperSlide>
        ))}
      </Swiper>
    </sc.SliderContainer>
  );
};

export default Slider;
