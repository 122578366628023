import gsap, { Power2 } from "gsap";

const InfoAnimation = () => {
  gsap.set(".info-animated", { y: 150, opacity: 0 });
  gsap.set(".info-card-overlay", { yPercent: -100 });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".info-wrapper",
        start: "30% bottom",
        end: "center center",
      },
    })
    .to(".info-animated", {
      duration: 1,
      y: 0,
      opacity: 1,
      ease: Power2.easeOut,
      stagger: 0.1,
    })
    .to(
      ".info-card-overlay",
      {
        duration: 1,
        yPercent: 0,
        opacity: 1,
        ease: Power2.easeInOut,
        stagger: 0.15,
      },
      "-=1"
    );
};

export default InfoAnimation;
