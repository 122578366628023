import gsap, { Power2 } from "gsap";

const DetailsAnimation = () => {
  gsap.set(".accordion-animated", { y: 150, opacity: 0 });
  gsap.set(".prizes-animated", { scaleX: 0 });
  gsap.set(".prizes-animated2", { scale: 0 });
  gsap.set(".prizes-animated3", { scale: 0 });
  gsap.set(".decoration-animated", { opacity: 0 });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".accordion-container",
        start: "40% bottom",
        end: "center center",
      },
    })
    .to(".accordion-animated", {
      duration: 1,
      y: 0,
      opacity: 1,
      ease: Power2.easeOut,
      stagger: 0.1,
    });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".details-wrapper",
        start: "30% bottom",
        end: "center center",
      },
    })
    .to(".prizes-animated", {
      duration: 0.75,
      scaleX: 1,
      ease: Power2.easeInOut,
      stagger: 0.1,
    })
    .to(
      ".prizes-animated2",
      {
        duration: 0.75,
        scale: 1,
        ease: Power2.easeOut,
        stagger: 0.1,
      },
      "-=.75"
    )
    .to(
      ".prizes-animated3",
      {
        duration: 0.5,
        scale: 1,
        ease: Power2.easeOut,
        stagger: 0.05,
      },
      "-=.75"
    )
    .to(
      ".decoration-animated",
      {
        duration: 1,
        opacity: 1,
      },
      "-=.5"
    );
};

export default DetailsAnimation;
