import { createGlobalStyle } from "styled-components";
import { COLORS } from "../utils";

const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-family: 'Montserrat', sans-serif;
    font-size: 62.5%;

    @media (max-width: 1024px) {
      font-size: 60%;
    }

    @media (max-width: 600px) {
      font-size: 57.5%;
    }

    @media (max-width: 330px) {
      font-size: 55%;
    }
  }

  body {
    color: white;
    min-height: 100vh;
    background: ${COLORS.mainBackground};
  }

  h1, h2, h3, h4, h5, h6 {
    span {
      background: ${COLORS.gradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export default GlobalStyles;
