import gsap, { Power2 } from "gsap";

const HeroAnimation = () => {
  gsap.set(".header", { y: -100, opacity: 0 });
  gsap.set(".hero-animated", { y: 100, opacity: 0 });
  gsap.set(".code-bg", { scaleY: 0 });
  gsap.set(".code-animated", { scaleX: 0 });
  gsap.set(".code-animated-ellipsis", { scale: 0 });

  const commonAnimation = {
    duration: 1,
    y: 0,
    opacity: 1,
    ease: Power2.easeOut,
    stagger: 0.1,
  };

  const commonAnimation2 = {
    duration: 0.5,
    ease: Power2.easeInOut,
    stagger: 0.1,
  };

  gsap
    .timeline()
    .to(".hero-animated", { delay: 3, ...commonAnimation })
    .to(".header", commonAnimation, "-=.75")
    .to(".code-animated-ellipsis", { ...commonAnimation2, scale: 1 }, "-=.5")
    .to(".code-animated", { ...commonAnimation2, scaleX: 1 }, "-=.5")
    .to(
      ".code-bg",
      { ...commonAnimation2, duration: 0.75, scaleY: 1 },
      "-=2.25"
    );

  gsap
    .timeline()
    .to(".code-animated", {
      ...commonAnimation2,
      delay: 7,
      scaleX: 1.25,
    })
    .to(".code-animated", {
      ...commonAnimation2,
      delay: 2.5,
      scaleX: 1,
    })
    .to(".code-animated", {
      ...commonAnimation2,
      delay: 2.5,
      scaleX: 0.65,
    })
    .to(".code-animated", {
      ...commonAnimation2,
      delay: 2.5,
      scaleX: 1,
    })
    .repeat(-1)
    .yoyo();
};

export default HeroAnimation;
