import gsap, { Power2 } from "gsap";

const HuprogAnimation = () => {
  gsap.set(".huprog-animated", { y: 150, opacity: 0 });
  gsap.set(".huprog-logo-overlay", { xPercent: 100 });

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".huprog-wrapper",
        start: "30% bottom",
        end: "center center",
      },
    })
    .to(".huprog-animated", {
      duration: 1,
      y: 0,
      opacity: 1,
      ease: Power2.easeOut,
      stagger: 0.1,
    })
    .to(
      ".huprog-logo-overlay",
      {
        duration: 1,
        xPercent: 0,
        ease: Power2.easeInOut,
      },
      "-=1"
    );
};

export default HuprogAnimation;
