import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";
import { Button, Container } from "./scMixins";

export const SponsorsWrapper = styled.div`
  background-color: ${COLORS.secondaryBackground};
  position: relative;
  z-index: 1;
  margin-bottom: 45rem;

  @media (max-width: 400px) {
    margin-bottom: 47.5rem;
  }
`;

export const SponsorsContainer = styled.div`
  ${Container};
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    padding-bottom: 5rem;
  }
`;

export const SponsorsContainerLeft = styled.div`
  width: 45%;
  padding: 4.5rem 0 2rem;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5%;

  @media (max-width: 1200px) {
    padding: 4rem 0;
    gap: 7.5%;
  }

  @media (max-width: 900px) {
    width: 100%;
    gap: 5rem;
  }
`;

export const SponsorsContainerRight = styled.div`
  width: 45%;
  overflow: hidden;
  background-color: ${COLORS.secondaryBackgroundLight};

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const DetailedSponsor = styled.div`
  width: 45%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const DetailedSponsorTitle = styled.h2`
  color: white;
  font-size: 1.4rem;
  font-weight: ${FONT_WEIGHTS.medium};
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

export const SponsorsRight = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SponsorsHeadingRight = styled.h2`
  font-size: 1.8rem;
  font-weight: ${FONT_WEIGHTS.medium};
  border-bottom: 1px solid ${COLORS.border};
  padding: 3rem;
`;

export const SponsorTitle = styled.h2`
  text-align: center;
  font-size: 22px;
`;

export const Sponsor = styled.a`
  width: 50%;
  height: 90px;
  padding: 2rem 4rem;

  &:last-child {
    padding: 0 4rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.border};
  }

  ${(props) =>
    props.$detailedSponsor ?
      `
      width: auto;
      padding: 2rem 1.5rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
      background-color: ${COLORS.secondaryBackgroundLight};

      img {
        width: 12rem;
        background-color: ${COLORS.secondaryBackgroundLight};
      }`
      :
      `
      width: auto;
      padding: 2rem 1.5rem !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: none;
      background-color: ${COLORS.secondaryBackgroundLight};
      img {
        width: 8rem;
        background-color: ${COLORS.secondaryBackgroundLight};
      }`
  }

  ${(props) =>
    props.$bgColor &&
    `
      background-color: ${props.$bgColor};

      img {
        background-color: ${props.$bgColor};
      }
  `}
`;

export const SponsorImage = styled.img`
  width: 11rem;

  @media (max-width: 1024px) {
    width: 8rem;
  }

  @media (max-width: 600px) {
    width: 12rem;
  }

  @media (max-width: 500px) {
    width: 10rem;
  }

  @media (max-width: 400px) {
    width: 8rem;
  }
`;

export const FooterWrapper = styled.div`
  padding: 10rem 0;
  background-image: url(${(props) => props.image});
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const FooterContainer = styled.div`
  ${Container}
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`;

export const FooterImage = styled.img`
  width: 12.5rem;
`;

export const FooterText = styled.p`
  width: 90%;
  margin: 0 auto;
  max-width: 50rem;
  line-height: 1.6;
  font-size: 1.4rem;
  color: ${COLORS.paragraphColor};
`;

export const FooterButton = styled.a`
  ${Button}
`;

export const FooterSocialGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const FooterSocialButton = styled.a`
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 100%;
  background-color: ${COLORS.darkPurple};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;

export const FooterSocialImage = styled.img``;
