import * as sc from "../styled/scDetails";
import Accordion from "./Accordion";
import Slider from "./Slider";
import AllPrizesImage from "../assets/details/allprizes.png";
import { DETAILS, PRIZES } from "../utils";

const Details = () => (
  <sc.DetailsWrapper className="details-wrapper">
    <sc.DetailsContainer>
      <sc.AllPrizes src={AllPrizesImage} />
      <Slider data={PRIZES} />
      <sc.AccordionContainer className="accordion-container">
        {DETAILS.map(({ title, content, color }, i) => (
          <Accordion
            key={i}
            title={title}
            content={content}
            color={color}
            className="accordion-animated"
          />
        ))}
      </sc.AccordionContainer>
    </sc.DetailsContainer>
  </sc.DetailsWrapper >
);

export default Details;
