import styled from "styled-components";
import { COLORS, FONT_WEIGHTS } from "../utils";

export const CounterWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CounterContainer = styled.div`
  display: flex;
  padding: 2.4rem;
  border-radius: 2.5rem;
  border: 1px solid ${COLORS.border};
  background-color: ${COLORS.secondaryBackground};
  background: radial-gradient(
    circle,
    rgba(42, 29, 57, 0.5) 0%,
    rgba(22, 22, 34, 1) 100%
  );

  @media (max-width: 600px) {
    padding: 2rem;
  }
`;

export const ElementContainer = styled.div`
  display: inline-block;
  display: flex;
  flex-direction: column;
  margin: 0 1.4rem;
  font-size: 1.4rem;
  text-align: center;

  strong {
    font-size: 2.8rem;
    font-weight: ${FONT_WEIGHTS.extrabold};

    @media (max-width: 600px) {
      font-size: 2.2rem;
    }
  }

  span {
    margin-top: 2.5px;
    color: ${COLORS.paragraphColor};
    font-weight: ${FONT_WEIGHTS.medium};
  }

  .blue {
    color: ${COLORS.blue};
  }

  .purple {
    color: ${COLORS.purple};
  }

  .pink {
    color: ${COLORS.pink};
  }

  .orange {
    color: ${COLORS.orange};
  }
`;
